import { liveQuery } from "dexie";
import { memo, useEffect, useRef, useState } from "react";
import { isMobile } from "react-device-detect";
import { db } from "../../db";
import { EfNodeType } from "../../graphql";
import { useHistoryManager } from "../../hooks/useHistoryManager";
import { useIosKeyboardHeight } from "../../hooks/useIosKeyboardHeight";
import { useProxyRef } from "../../hooks/useProxyRef";
import { EfNode, EfNodeEditorData } from "../../types";
import { EditorContextMenuWrapper } from "../ContentMenuWrapper/EditorContextMenuWrapper";
import { NewNodeRenderer } from "../NewNodeRenderer";
import {
  SelectionWrapper,
  useAreaSelection,
} from "../SelectionWrapper/SelectionWrapper";
import { MobileToolbar } from "./MobileToolbar/MobileToolbar";
import { onLift, onSink } from "./editorUtils/liftAndSink";
import { ConfirmPastePopup, usePastePopup } from "../ConfirmPastePopup";
import { onToggleTask } from "./editorUtils/task";
import {
  onDeleteBackwards,
  onDeleteForwards,
} from "./editorUtils/joinAndSplit";
import {
  onArrowDown,
  onArrowLeft,
  onArrowRight,
  onArrowUp,
} from "./editorUtils/cursor";
import { onBlur } from "./editorUtils/blur";
import { onCreate } from "./editorUtils/create";
import { onPaste } from "./editorUtils/paste";
import { onFocus } from "./editorUtils/focus";
import { onSelectBackward, onSelectForward } from "./editorUtils/selection";
import { isEqual } from "lodash";
import { onUpdate } from "./editorUtils/update";

export type SingleNodeEditorState = Awaited<ReturnType<typeof querier>>;

function SingleNodeEditorBase({
  parentNode,
  isActive,
  setActive,
  createNewEmptyNode = false,
  containerScrollRef,
  headerRef,
}: {
  parentNode: EfNode;
  isActive: boolean;
  setActive: (id: string) => void;
  createNewEmptyNode?: boolean;
  containerScrollRef?: React.RefObject<HTMLElement>;
  headerRef?: React.RefObject<HTMLDivElement>;
}) {
  const [currentId, setCurrentId] = useState<string | null>(null);
  const [state, setState] = useState<SingleNodeEditorState>();
  // Context menu
  const [selectedContextMenuNode, setSelectedContextMenuNode] = useState<
    string | undefined
  >(undefined);

  // Confirm Paste
  const { showConfirmPaste, setShowConfirmPaste, onSubmitPasteRef } =
    usePastePopup();

  const iosKeyboardHeight = useIosKeyboardHeight();

  const stateRef = useProxyRef(state);

  const mobileToolbarRef = useRef<HTMLDivElement>(null);
  const scrollRef = useRef<HTMLDivElement>(null);

  const someNodeIsFocused = currentId !== null;
  const history = useHistoryManager({ someNodeIsFocused, disabled: !isActive });
  const areaSelection = useAreaSelection(scrollRef, state);

  const relativeDepth =
    (parentNode.computed.pathInPage?.split("/").length || 3) - 3;

  useEffect(() => {
    const observable = liveQuery(() => querier(parentNode));
    const subscription = observable.subscribe((newState) => {
      setState(newState);
    });
    return () => subscription.unsubscribe();
  }, [parentNode, stateRef]);

  useEffect(() => {
    if (isActive) {
      setCurrentId(parentNode.id);
    } else {
      areaSelection.setAreaSelectedBounds({});
      setCurrentId(null);
    }
  }, [isActive]);

  if (!state) return null;

  return (
    <>
      <div
        ref={scrollRef}
        className="flex-1 px-2 overscroll-none"
        style={{
          overflowAnchor: "none",
        }}
        onMouseDown={() => setActive(parentNode.id)}
      >
        <EditorContextMenuWrapper
          setNodeId={setSelectedContextMenuNode}
          nodeId={selectedContextMenuNode}
        >
          <SelectionWrapper
            history={history}
            virtualEditorState={state}
            areaSelection={areaSelection}
            scrollRef={scrollRef}
            hideArea
            rootNodeId={parentNode.id}
          >
            <div>
              {state.nodes.map((node, index, nodes) => (
                <NewNodeRenderer
                  key={node.id}
                  rootNodeId={parentNode.id}
                  addEndDecorationPlugin
                  history={history}
                  node={node}
                  focused={node.id === currentId}
                  focusType={parentNode.id === node.id ? "end" : undefined}
                  relativeDepth={relativeDepth}
                  onArrowUp={onArrowUp(
                    areaSelection.clearSelectedNodes,
                    nodes,
                    index,
                    setCurrentId
                  )}
                  onArrowDown={onArrowDown(
                    areaSelection.clearSelectedNodes,
                    nodes,
                    index,
                    setCurrentId
                  )}
                  onArrowLeft={onArrowLeft(areaSelection.clearSelectedNodes)}
                  onArrowRight={onArrowRight(areaSelection.clearSelectedNodes)}
                  onFocus={onFocus(
                    node,
                    containerScrollRef,
                    setCurrentId,
                    iosKeyboardHeight,
                    headerRef
                  )}
                  onUpdate={(nodeElement) => {
                    onUpdate(
                      nodeElement,
                      containerScrollRef?.current,
                      iosKeyboardHeight,
                      headerRef
                    );
                  }}
                  onBlur={onBlur(mobileToolbarRef)}
                  onCreate={onCreate(
                    history,
                    node,
                    setCurrentId,
                    parentNode.id === node.id,
                    createNewEmptyNode && parentNode.id === node.id
                  )}
                  onEscape={() => {
                    // Do nothing
                  }}
                  onDeleteBackward={onDeleteBackwards(
                    history,
                    nodes,
                    node,
                    index,
                    setCurrentId
                  )}
                  onDeleteForward={onDeleteForwards(
                    history,
                    nodes,
                    node,
                    index,
                    setCurrentId
                  )}
                  onSink={onSink(history, node, setCurrentId)}
                  onLift={onLift(history, node, setCurrentId)}
                  selectMode={false}
                  isAreaSelection={
                    areaSelection.isAreaSelectionActive() &&
                    areaSelection.areaSelectedBounds.anchorNode !==
                      areaSelection.areaSelectedBounds.currentNode
                  }
                  selected={areaSelection.isNodeSelected(node)}
                  onSelectForward={onSelectForward(
                    nodes,
                    node,
                    areaSelection,
                    index,
                    setCurrentId,
                    areaSelection.selectNode
                  )}
                  onSelectBackward={onSelectBackward(
                    nodes,
                    node,
                    areaSelection,
                    index,
                    setCurrentId,
                    areaSelection.selectNode
                  )}
                  toggleTask={onToggleTask(node, history)}
                  onPaste={onPaste(history, node, setCurrentId)}
                  getPreviousNodeTagIds={() => {
                    return nodes[index - 1]?.tagIds ?? [];
                  }}
                  showConfirmPastePopup={(onSubmit) => {
                    setShowConfirmPaste(true);
                    onSubmitPasteRef.current = onSubmit;
                  }}
                  headerRef={headerRef}
                />
              ))}
            </div>
          </SelectionWrapper>
        </EditorContextMenuWrapper>
      </div>
      {isMobile && isActive && (
        <MobileToolbar
          state={state}
          currentId={currentId}
          setCurrentId={setCurrentId}
          historyManager={history}
          toolbarRef={mobileToolbarRef}
          relativeDepth={relativeDepth}
          jumpToTop={async () => {
            // Do nothing
          }}
        />
      )}
      <ConfirmPastePopup
        isOpen={showConfirmPaste}
        onSubmit={(pasteAsList) => {
          if (onSubmitPasteRef.current) {
            onSubmitPasteRef.current(pasteAsList);
          }
          setShowConfirmPaste(false);
          onSubmitPasteRef.current = null;
        }}
      />
    </>
  );
}

export const SingleNodeEditor = memo(
  SingleNodeEditorBase,
  (prevProps, nextProps) => isEqual(prevProps, nextProps)
);
async function querier(parentNode: EfNode) {
  const filterNodes = (node: EfNode) => {
    return [EfNodeType.Block, EfNodeType.Task].includes(node.nodeType);
  };
  const [nodes] = await Promise.all([
    db.nodes
      .where("[computed.visible+computed.pathInPage]")
      .aboveOrEqual([1, parentNode.computed.pathInPage])
      .until((item) => {
        if (![EfNodeType.Block, EfNodeType.Task].includes(item.nodeType)) {
          return false;
        }
        return !item.computed.pathInPage?.startsWith(
          parentNode.computed.pathInPage!
        );
      })
      .filter(filterNodes)
      .toArray(),
  ]);
  return { nodes };
}
