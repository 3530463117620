import { BOTTOM_TOOLBAR_HEIGHT } from "./MobileToolbar/MobileToolbar";

// Get selection position relative to it's parent
const getSelectionPosition = (comp: HTMLElement) => {
  const selection = window.getSelection();
  if (!selection?.rangeCount) return;

  const range = selection.getRangeAt(0);
  const rangeRect = range.getBoundingClientRect();

  // If the selection range is empty - return nothing
  if (rangeRect.width === 0 && rangeRect.height === 0) return;

  const componentRect = comp.getBoundingClientRect();

  const relativeX = rangeRect.left - componentRect.left + rangeRect.width;
  const relativeY = rangeRect.top - componentRect.top + rangeRect.height;

  return { x: relativeX, y: relativeY };
};

export const scrollNodeAboveKeyboard = (
  scrollElement: HTMLElement,
  nodeElement: HTMLElement,
  headerElement?: HTMLElement | null,
  keyboardHeight: number = window.lastIosKeyboardHeight
) => {
  // Get selection position relative to it's parent
  const relativeSelection = getSelectionPosition(nodeElement);
  const keyboardWithToolbarHeight = keyboardHeight + BOTTOM_TOOLBAR_HEIGHT;
  const nodeOffset = nodeElement.offsetTop;
  const scrollTop = scrollElement.scrollTop;
  const visibleArea =
    scrollElement.clientHeight -
    keyboardWithToolbarHeight +
    (headerElement?.clientHeight || 0);
  const relativeNodePosition =
    // calculate the relative position of the node and account for the height of the selection inside the node
    // if the relative selection is not defined we default to the original height of the node
    nodeOffset - scrollTop + (relativeSelection?.y || nodeElement.clientHeight);
  const isHiddenByKeyboard = relativeNodePosition > visibleArea;

  if (isHiddenByKeyboard) {
    scrollElement.scrollTo({
      top: scrollTop + relativeNodePosition - visibleArea,
      behavior: "instant",
    });
  }
};

export const isKeyboardHidden = (
  scrollElement: HTMLElement,
  nodeElement: HTMLElement,
  scrollTop: number,
  keyboardHeight: number = window.lastIosKeyboardHeight
) => {
  const keyboardWithToolbarHeight = keyboardHeight + BOTTOM_TOOLBAR_HEIGHT;
  const nodeOffset = nodeElement.offsetTop;
  const visibleArea = scrollElement.clientHeight - keyboardWithToolbarHeight;
  const relativeNodePosition =
    nodeOffset - scrollTop + nodeElement.clientHeight;
  return relativeNodePosition > visibleArea;
};
